export const RouteMap = {
  ROOT: '/',
  HOME: '/home',
  ABOUT: '/aboutus',
  CONTACT: '/contactus',
  FAQ: '/faqs',
  CATEGORY_1: '/category/:categoryId([0-9]+)/:categorySlug/',
  CATEGORY_2: '/:categorySlug([\\w-]+)/c:categoryId([0-9]+)/',
  PRODUCT_REVIEW: '/product/:productSlug([\\w-]+)/review',
  PRODUCT: '/product/:productSlug([\\w-]+)/',

  ACCOUNT_ORDERS: '/account/orders',
  ACCOUNT_ORDER_DETAIL: '/account/order/:orderId',
  ACCOUNT_PROFILE: '/account/profile',
  ACCOUNT_INVITE: '/account/invite',
  ACCOUNT_HELP: '/account/help',
  ACCOUNT_ADDRESSES: '/account/addresses',
  ACCOUNT_ADDRESS_ADD: '/account/address/add',
  ACCOUNT_ADDRESS_EDIT: '/account/address/edit/:addressId([0-9]+)',
  ACCOUNT_LOGIN: '/account/login',
  ACCOUNT_FORGOT_PASSWORD: '/account/forgot-password',
  ACCOUNT_REGISTER: '/account/register',
  ACCOUNT_WISHLIST: '/account/wishlist',

  ACCOUNT_LOGIN_GOOGLE: '/account/login/google',
  ACCOUNT_LOGIN_FACEBOOK: '/account/login/facebook',
  ACCOUNT_REGISTER_GOOGLE: '/account/register/google',
  ACCOUNT_REGISTER_FACEBOOK: '/account/register/facebook',
  ACCOUNT_EMAIL_ACTIVATION: '/user/activate/:activationCode/:activationToken',

  ORDER_CONFIRMATION_1: '/order/confirmation',
  ORDER_CONFIRMATION_2: '/payment-response/:orderId([0-9]+)/',
  POLICY: '/policy/:policyType',
  SEARCH: '/search/',
  CAPTCHA: '/captcha/',

  TRACK_ORDER: '/track-order',
  TRACK_ORDER_STATUS: '/track-order-status',
  TRACK_ORDER_DETAILS: '/track-order-details',

  CUSTOM_PAGE_1: '/p',
  CUSTOM_PAGE_2: '/',
  CART: '/cart',
  ALL_PRODUCTS: '/all-products',
  SHOP_ALL: '/shop',
  CHECKOUT_ORDER_CONFIRMATION: '/confirmation/:orderId',

  REFERRAL_PROGRAM: '/referral-program',

  UNSUBSCRIBE: '/unsubscribe',

  SEO_POLICY_TERMS_AND_CONDITIONS: '/terms-and-conditions',
  SEO_POLICY_CANCELLATION_AND_REFUND: '/cancellation-refund-policy',
  SEO_POLICY_PRIVACY: '/privacy-policy',
  SEO_POLICY_SHIPPING_AND_DELIVERY: '/shipping-delivery-policy',

  //Admin Routes
  BUILDER_SECTION: '/builder/sections',
  BUILDER_HERO_BANNER_CREATE: '/builder/sections/hero-banner/create',
  BUILDER_HERO_BANNER_EDIT: '/builder/sections/hero-banner/edit/:id([0-9]+)',
  BUILDER_PAGE_HEADER_CREATE: '/builder/sections/page-header/create',
  BUILDER_PAGE_HEADER_EDIT: '/builder/sections/page-header/edit/:id([0-9]+)',
  BUILDER_HTML_BLOCK_CREATE: '/builder/sections/html-block/create',
  BUILDER_HTML_BLOCK_EDIT: '/builder/sections/html-block/edit/:id([0-9]+)',
  BUILDER_IMAGE_TEXT_CREATE: '/builder/sections/image-text/create',
  BUILDER_IMAGE_TEXT_EDIT: '/builder/sections/image-text/edit/:id([0-9]+)',
  BUILDER_RICH_TEXT_EDITOR_CREATE: '/builder/sections/rich-text-editor/create',
  BUILDER_RICH_TEXT_EDITOR_EDIT: '/builder/sections/rich-text-editor/edit/:id([0-9]+)',
  BUILDER_TEXT_CREATE: '/builder/sections/text/create',
  BUILDER_TEXT_EDIT: '/builder/sections/text/edit/:id([0-9]+)',
  BUILDER_TESTIMONIALS_CREATE: '/builder/sections/testimonial/create',
  BUILDER_TESTIMONIALS_EDIT: '/builder/sections/testimonial/edit/:id([0-9]+)',
  BUILDER_ADD_NEW: '/builder/sections/add-new',
  BUILDER_PRODUCT_SHOWCASE_CREATE: '/builder/sections/product-showcase/create',
  BUILDER_PRODUCT_SHOWCASE_EDIT: '/builder/sections/product-showcase/edit/:id([0-9]+)',
  BUILDER_CATEGORY_SHOWCASE_CREATE: '/builder/sections/category-showcase/create',
  BUILDER_CATEGORY_SHOWCASE_EDIT: '/builder/sections/category-showcase/edit/:id([0-9]+)',
  BUILDER_STORE_UPDATE_CREATE: '/builder/sections/store-update/create',
  BUILDER_STORE_UPDATE_EDIT: '/builder/sections/store-update/edit/:id([0-9]+)',

  CUSTOM_COLOURS: '/customizestore/colours',
  CUSTOM_FONTS: '/customizestore/fonts',
  THEME: '/customizestore/themes',
  PREVIEW_THEME: '/customizestore/themes/preview',
  EDIT_THEME: '/customizestore/themes/edit',
  CUSTOM_PAGE: '/customizestore/pages',
  PAGE_CREATE: '/customizestore/pages/create',
  PAGE_EDIT: '/customizestore/pages/edit/:id([0-9]+)',
};
