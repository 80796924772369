import DefaultProductImage from '@common/images/DefaultProductImage.png';

import { convertToMyInstamojoDomain, isBrowser } from './index';

export enum PRODUCTS_KEY {
  FEATURED = 'featured',
  LATEST = 'latest',
  ALL = 'all',
  CATEGORY = 'category',
  SEARCH = 'search',
  SHOP = 'shop',
}

export enum SEARCH_KEY {
  SUGGESTION = 'suggestion',
  SEARCH = 'search',
}

export const POLICY_TYPE = {
  privacy: 'privacy',
  tnc: 'termsAndConditions',
  shipping: 'shippingAndDelivery',
  cancellation: 'cancelAndRefund',
};

export const DEFAULT_PRODUCT_IMAGE = DefaultProductImage;

export interface Context {
  BASE_INSTAMOJO_URL: string;
  BASE_MOJOCOMMERCE_CORE_URL: string;
  BASE_MOJOCOMMERCE_CORE_PRIVATE_URL?: string;
  BASE_MOJOCOMMERCE_DASHBOARD_URL: string;
  SSO_SP_URL: string;
  HINT_COOKIE: string;
  MIXPANEL_TOKEN: string;
  SENTRY_DSN: string;
  SENTRY_ENV: string;
  MERCHANT_HOSTNAME: string;
  URL: string;
  MERCHANT_TOKEN_NAME: string;
  BUYER_TOKEN_NAME: string;
  GOOGLE_CLIENT_ID: string;
  FACEBOOK_APP_ID: string;
  MERCHANT_TOKEN?: string;
  BUYER_TOKEN?: string;
  WITH_CREDENTIALS: boolean;
  GOOGLE_RECAPTCHA_CLIENT_ID: string;
  SOCIAL_LOGIN_BASE_URL: string;
  STOREFRONT_ENV_TYPE: string;
  PREVIEW_THEME?: string;
  PREVIEW_COOKIE_NAME: string;
  USER_AGENT?: string;
  WEBP_SUPPORT: boolean;
  MERCHANT_ACCOUNT_ID: number;
  QUERY_PARAMS: Record<string, string>;
  FILESTACK_API_KEY: string;
  FILESTACK_STORAGE_BUCKET: string;
  IS_MEDIAMANAGER_ENABLED: boolean;
  MEDIA_S3_URL: string;
  IFRAME_URL: string;
  MS_CLARITY_PROJECT_ID: string;
  S3_URL: string;
}

export let context: Context;
export let cachedData = {};
export let accountId = null;
export let accountUsername = null;
export let isShowAllLayout = false;

export const setContext = (c?: Context): void => {
  if (c) {
    context = c;
  } else {
    /* eslint-disable-next-line */
    context = (window as any).context as Context;
  }
};

if (!context && !(typeof window === 'undefined')) {
  setContext();
}

export const setAccountId = (id: number): void => {
  accountId = id;
};

export const getAccountId = (): number => {
  // return accountId;
  if (isBrowser() && new URLSearchParams(window.location.search).get('account-id')) {
    return Number(new URLSearchParams(window.location.search).get('account-id'));
  }
  return context.MERCHANT_ACCOUNT_ID;
};

export const setAccountUsername = (username: string): void => {
  accountUsername = username;
};

export const setCachedData = (data: Record<string, unknown>): void => {
  cachedData = data;
};

export const getCachedData = (url: string): Record<string, unknown> => {
  return cachedData?.[url] || null;
};

export const setShowAllLayout = (value: boolean): void => {
  isShowAllLayout = value;
};

// export const getShowAllLayout = (): boolean => {
//   if (isBrowser() && new URLSearchParams(window.location.search).get('show')) {
//     return true;
//   }
//   return false;
// };

export const getAddProductUrl = (): string => `${context.BASE_MOJOCOMMERCE_DASHBOARD_URL}dashboard/products/create`;
export const getAddCategoryUrl = (): string => `${context.BASE_MOJOCOMMERCE_DASHBOARD_URL}dashboard/categories/create`;
export const getDashboardUrl = (): string => `${context.BASE_MOJOCOMMERCE_DASHBOARD_URL}dashboard`;
export const getBaseInstamojoUrl = (): string => `${context.BASE_INSTAMOJO_URL}`;
export const getSubscriptionUrl = (): string => `${context.BASE_INSTAMOJO_URL}subscription/`;
export const getAddTestimonialsUrl = (): string =>
  `${context.BASE_MOJOCOMMERCE_DASHBOARD_URL}dashboard/store-testimonials`;
export const getAddFooterUrl = (): string => `${context.BASE_MOJOCOMMERCE_DASHBOARD_URL}dashboard/social-media`;
export const getStoreUrl = (): string => `https://${context.MERCHANT_HOSTNAME}/`;
export const getReferralUrl = (code: string): string =>
  `https://${context.MERCHANT_HOSTNAME}/account/register/?referrer_code=${code}`;
export const getGoogleLoginUrl = (redirectUrl: string): string =>
  `${context.SOCIAL_LOGIN_BASE_URL}account/login/google?redirect_uri=${redirectUrl}`;
export const getFacebookLoginUrl = (redirectUrl: string): string =>
  `${context.SOCIAL_LOGIN_BASE_URL}account/login/facebook?redirect_uri=${redirectUrl}`;
export const getGoogleRegisterUrl = (redirectUrl: string): string =>
  `${context.SOCIAL_LOGIN_BASE_URL}account/register/google?redirect_uri=${redirectUrl}`;
export const getFacebookRegisterUrl = (redirectUrl: string): string =>
  `${context.SOCIAL_LOGIN_BASE_URL}account/register/facebook?redirect_uri=${redirectUrl}`;
export const getCaptchaUrl = (): string =>
  process.env.NODE_ENV === 'development'
    ? '/captcha'
    : `${context.BASE_MOJOCOMMERCE_CORE_URL}captcha?redirect_uri=${context.URL}`;
export const getInstamojoRegisterUrl = (): string => `${context.BASE_INSTAMOJO_URL}accounts/register`;
export const getCustomDomainUrl = (): string => `${getDashboardUrl()}/custom-domain`;
export const getBaseMojoCommerceCoreUrl = (): string => `${context.BASE_MOJOCOMMERCE_CORE_URL}`;
export const getFacebookImportUrl = (): string =>
  `${convertToMyInstamojoDomain(window.location.origin)}/facebook-import`;
export const getQueryParams = (): Record<string, string> => {
  return isBrowser() ? Object.fromEntries(new URLSearchParams(window.location.search)) : context.QUERY_PARAMS;
};
export const getMediaManagerAPIKey = (): string => context.FILESTACK_API_KEY;
export const getMediaManagerStorageBucket = (): string => context.FILESTACK_STORAGE_BUCKET;
export const getIframeUrl = (): string => context.IFRAME_URL;
export const getMSClarityProjectID = (): string => context.MS_CLARITY_PROJECT_ID;
export const getS3URL = (): string => context.S3_URL;

export const CART_LOCAL_STORAGE_KEY = 'NewCart';
export const CART_LOCAL_STORAGE_BACKUP_KEY = 'NewCartBackup';
export const CART_EMBED_KEY = 'EmbedCart';
export const CART_HASH_LOCAL_STORAGE_KEY = 'cartHash';

export const COOKIE_KEYS = {
  LOGIN_TYPE: 'loginType',
  IS_CART_ORDER: 'isCartOrder',
  CART_PRODUCT_TYPE: 'cartProductType',
  AFTER_LOGIN_REDIRECT: 'afterLoginRedirect',
};

export const virtualEventVenue = (): string =>
  'This is a virtual event. The link to join the event will be shared with you.';

export const CASH_ON_DELIVERY = 'Cash-On-Delivery';

export const RE_SEND_OTP_TIMER = {
  SECOND: 0,
  MINUTE: 2,
};

export const RE_SEND_OTP_CONTENT = {
  RESEND_OTP_IN: 'Resend OTP in',
  AFTER_RESEND_OTP: 'OTP sent, resend OTP in',
  OTP_LIMIT_RREACHED: 'OTP sent, limit reached',
};

export const NO_OF_ONBOARDING_STEPS = 7;

export const CUSTOM_LINK_LABELS = ['Contact Us'];
export const CANCEL_ORDER_ACTIVE = ['initiated', 'processing', 'dispatched'];
export const CUSTOM_FIELDS_MAX_FILE_SIZE = 1024 * 1024 * 10;

export const getFormedUrl = (url: string, params: unknown): string => {
  if (params && Object.keys(params || [])?.length) {
    const queryString = Object.keys(params)
      .map((key) => key + '=' + params[key])
      .join('&');
    return `${url}?${queryString}`;
  }

  return url;
};

export const NO_OF_IMAGES_LAYOUT_MAPPING = {
  ImageText1: 1,
  ImageText2: 1,
  ImageText3: 1,
  ImageText4: 1,
  ImageText5: 1,
  ImageText6: 2,
  ImageText7: 1,
  ImageText8: 1,
  ImageText9: 1,
  ImageText10: 1,
};

export const NO_OF_TEXT_LAYOUT_MAPPING = {
  ImageText1: 1,
  ImageText2: 1,
  ImageText3: 1,
  ImageText4: 1,
  ImageText5: 1,
  ImageText6: 1,
  ImageText7: 1,
  ImageText8: 1,
  ImageText9: 1,
  ImageText10: 1,
  Text1: 1,
  Text2: 1,
  Text3: 1,
  Text4: 2,
  Text5: 3,
  Text6: 1,
  Text7: 1,
  Text8: 1,
  Text9: 1,
  Text10: 1,
};

export enum CATEGORY_TYPE {
  FEATURED_CATEGORIES = 2,
  SPECIFIC_CATEGORIES = 3,
}

export enum ON_MESSAGE_TYPE {
  RTE = 'RTE_UPDATE',
  TEXT = 'TEXT_UPDATE',
  IMAGE_TEXT = 'IMAGE_TEXT_UPDATE',
  HTML_BLOCK = 'HTML_BLOCK_UPDATE',
}

export const getQueryParamByName = (url: string, param: string): string => {
  const urlParams = new URLSearchParams(url);
  return urlParams.get(param);
};

export const LATEST_PRODUCTS = '1';
export const ALL_PRODUCTS = '4';
export const FEATURED_PRODUCTS = '2';
export const SPECIFIC_PRODUCTS = '3';
export const ADD_PRODUCT = 'Add Products';

export const MOBILE_AND_WEB = '1';
export const WEB = '2';
export const MOBILE = '3';

export const PAGE_HEADER = 'PAGE_HEADER';
export const HERO_BANNER = 'HERO_BANNER';
export const TEXT = 'TEXT';
export const RICH_TEXT_EDITOR = 'RICH_TEXT_EDITOR';
export const IMAGE_TEXT = 'IMAGE_TEXT';
export const HTML_BLOCK = 'HTML_BLOCK';
export const CATEGORY_SHOWCASE = 'CATEGORY_SHOWCASE';
export const PRODUCT_SHOWCASE = 'PRODUCT_SHOWCASE';
export const STORE_UPDATES = 'STORE_UPDATES';
export const TESTIMONIALS = 'TESTIMONIALS';
export const ADD_NEW_SECTION_ID = '-1';
export const ALLOWED_BANNER_THRESHOLD = 10;
export const LAYOUT_IMAGE_WIDTH = 700;
export const LAYOUT_IMAGE_HEIGHT = 300;

export const firstTimeBuilderIntroModalBodyContent = [
  {
    featureList:
      'Introducing page sections such as page headers, hero banners, testimonials, product showcase and more for you to choose from while building your homepage.',
  },
  { featureList: 'Choose the section that best serves your requirements from the collection.' },
  {
    featureList: 'You now have the ability to add, edit, reorder, and manage sections according to your requirements.',
  },
  { featureList: 'Preview your homepage in real-time as you build it to be sure of exactly what you want.' },
];

export const upgradedFeaturesList = [
  { featureList: 'Full-featured Builder access', isNew: true },
  { featureList: 'No Instamojo Branding on the Store', isNew: false },
  { featureList: 'Custom Domain Linking', isNew: false },
  { featureList: 'Abandoned Cart Recovery', isNew: false },
  { featureList: 'Discount Coupons', isNew: false },
  { featureList: 'Advanced Analytics', isNew: false },
];

export const imageAndTextAspectRatio = {
  ImageText1: {
    width: 1142,
    height: 640,
    widthRatio: 16,
    heightRatio: 9,
  },
  ImageText2: {
    width: 560,
    height: 420,
    widthRatio: 4,
    heightRatio: 3,
  },
  ImageText3: {
    width: 560,
    height: 700,
    widthRatio: 4,
    heightRatio: 5,
  },
  ImageText4: {
    width: 560,
    height: 315,
    widthRatio: 16,
    heightRatio: 9,
  },
  ImageText5: {
    width: 1142,
    height: 640,
    widthRatio: 16,
    heightRatio: 9,
  },
  ImageText6: {
    width: 364,
    height: 364,
    widthRatio: 1,
    heightRatio: 1,
  },
  ImageText7: {
    width: 756,
    height: 756,
    widthRatio: 1,
    heightRatio: 1,
  },
};

export const windowScrollToTop = (scrollTo: number): void => {
  window.scrollTo({
    top: scrollTo || 0,
    behavior: 'smooth',
  });
};

export const getLocalStorageItem = (storageName: string): string => {
  return localStorage.getItem(storageName);
};

export const setLocalStorageItem = (storageName: string, value = false): void => {
  return localStorage.setItem(storageName, value);
};

export const categoryParentClassName = (category: { name: string }): string => {
  return 'mobile-' + category?.name?.toLowerCase().split(' ').join('-') + '-div';
};

interface pagemetaTags {
  name: string;
  content: string;
}

export const getAboutUsPageMetaTags = (storeName: string): Array<pagemetaTags> => [
  {
    name: 'title',
    content: `Who We Are | About Us | ${storeName}`,
  },
  {
    name: 'description',
    content: `Learn more about ${storeName} and our story, mission, and values. Get to know our team and why we're dedicated to providing you with the best possible experience.`,
  },
];

export const getFaqsMetaTags = (storeName: string): Array<pagemetaTags> => [
  {
    name: 'title',
    content: `FAQs | Frequently Asked Questions | ${storeName}`,
  },
  {
    name: 'description',
    content: `Get answers to your most commonly asked questions with ${storeName} FAQs page.`,
  },
];
export const getContactUsMetaTags = (storeName: string): Array<pagemetaTags> => [
  {
    name: 'title',
    content: `Contact Us | ${storeName}`,
  },
  {
    name: 'description',
    content: `Need assistance or have a question? Contact ${storeName} and our dedicated customer service like phone & email will be available & our team will be happy to help.`,
  },
];
export const getPrivacyPolicyMetaTags = (storeName: string): Array<pagemetaTags> => [
  {
    name: 'title',
    content: `Privacy Policy | ${storeName}`,
  },
  {
    name: 'description',
    content: `${storeName} privacy policy outlines how we collect payment, use, and protect your personal information when you use our website or interact with our products and services. We are taking care privacy seriously.`,
  },
];

export const getTermsAndConditionsMetaTags = (storeName: string): Array<pagemetaTags> => [
  {
    name: 'title',
    content: `Terms and Conditions | ${storeName}`,
  },
  {
    name: 'description',
    content: `By using ${storeName} website and products, you agree to our terms and conditions. Our policies and guidelines outline your rights and responsibilities as a user of our services.`,
  },
];

export const getCancelAndRefundMetaTags = (storeName: string): Array<pagemetaTags> => [
  {
    name: 'title',
    content: `Cancellation and Refund policy | ${storeName}`,
  },
  {
    name: 'description',
    content: `Know more about our ${storeName} cancellation and refund policy outlines how we handle order changes and returns.`,
  },
];

export const getShippingAndDeliveryMetaTags = (storeName: string): Array<pagemetaTags> => [
  {
    name: 'title',
    content: `Policies for Shipping and Delivery | ${storeName}`,
  },
  {
    name: 'description',
    content: `We're committed to providing you with timely and efficient shipping and delivery services. Learn more about ${storeName} eCommerce shipping and delivery policy outlines our guidelines and procedures for shipping, delivery, and tracking.`,
  },
];

export const getCategoryMetaTags = (storeName: string, categoryName: string): Array<pagemetaTags> => [
  {
    name: 'title',
    content: `Buy ${categoryName} online from ${storeName} from your eCommerce store in India`,
  },
  {
    name: 'description',
    content: `Buy ${categoryName} online from ${storeName} in India. Pick from Huge collection ${categoryName} at low price from eCommerce store. Discounts, COD & easy returns & exchanges available. Order now.`,
  },
];

export const heroBannerDimensions = {
  HB1: {
    width: 608,
    height: 436,
  },
  HB2: {
    width: 1440,
    height: 554,
  },
  HB3: {
    width: 1440,
    height: 598,
  },
  HB4: {
    width: 1440,
    height: 509,
  },
  HB5: {
    width: 1440,
    height: 650,
  },
  HB6: {
    width: 1440,
    height: 652,
  },
  HB7: {
    width: 1440,
    height: 563,
  },
  HB8: {
    width: 1008,
    height: 550,
  },
  HB9: {
    width: 1440,
    height: 650,
  },
  HB10: {
    width: 1152,
    height: 270,
  },
  HB11: {
    width: 366,
    height: 206,
  },
};

export const customColor = {
  color_page_background: 'Background',
  color_page_headings: 'Headings',
  color_page_body_text: 'Body Text',
  color_page_button_background: 'Button Background',
  color_page_button_text: 'Button Text',
  color_footer_background: 'Background',
  color_footer_heading: 'Heading',
  color_footer_text: 'Text',
  color_footer_link: 'Link',
  color_header_background: 'Background',
  color_header_links: 'Links',
  color_header_banner_heading: 'Heading Text',
  color_header_banner_subheading: 'Sub Heading Text',
  color_header_banner_button_background: 'Button Background',
  color_header_banner_button_text: 'Button Text',
  color_header_banner_button_border: 'Button Border',
  color_page_button_border: 'Button Border',
  color_cart_addtocart_background: 'Add to Cart Background',
  color_cart_addtocart_text: 'Add to Cart Text',
  color_cart_addtocart_border: 'Add to Cart border',
  color_cart_buynow_background: 'Buy Now Background',
  color_cart_buynow_text: 'Buy Now Text',
  color_cart_buynow_border: 'Buy Now Border',
  color_cart_whishlist_background: 'Wishlist Background',
  color_cart_whishlist_text: 'Wishlist Text',
  color_cart_whishlist_border: 'Wishlist Border',
};
export const categoryShowCaseType = [
  {
    typeName: 'Featured Categories',
    value: '2',
  },
  {
    typeName: 'Specific Categories',
    value: '3',
  },
];

export const CATEGORY_SHOWCASE_LAYOUT_CONFIG = {
  CS1: {
    showCaseTypes: categoryShowCaseType,
    isSpecificCategoryOnly: false,
    MIN_CATEGORIES: 0,
    MAX_CATEGORIES: 999999,
  },
  CS2: {
    showCaseTypes: categoryShowCaseType,
    isSpecificCategoryOnly: false,
    MIN_CATEGORIES: 0,
    MAX_CATEGORIES: 999999,
  },
  CS3: {
    showCaseTypes: categoryShowCaseType,
    isSpecificCategoryOnly: false,
    MIN_CATEGORIES: 0,
    MAX_CATEGORIES: 999999,
  },
  CS4: {
    showCaseTypes: categoryShowCaseType,
    isSpecificCategoryOnly: false,
    MIN_CATEGORIES: 0,
    MAX_CATEGORIES: 999999,
  },
  CS5: {
    showCaseTypes: categoryShowCaseType,
    isSpecificCategoryOnly: false,
    MIN_CATEGORIES: 0,
    MAX_CATEGORIES: 999999,
  },
  CS6: {
    showCaseTypes: categoryShowCaseType,
    isSpecificCategoryOnly: false,
    MIN_CATEGORIES: 0,
    MAX_CATEGORIES: 999999,
  },
  CS7: {
    showCaseTypes: categoryShowCaseType,
    isSpecificCategoryOnly: false,
    MIN_CATEGORIES: 0,
    MAX_CATEGORIES: 999999,
  },
  CS8: {
    showCaseTypes: categoryShowCaseType,
    isSpecificCategoryOnly: false,
    MIN_CATEGORIES: 0,
    MAX_CATEGORIES: 999999,
  },
  CS9: {
    showCaseTypes: categoryShowCaseType,
    isSpecificCategoryOnly: false,
    MIN_CATEGORIES: 0,
    MAX_CATEGORIES: 999999,
  },
  CS10: {
    showCaseTypes: categoryShowCaseType,
    isSpecificCategoryOnly: false,
    MIN_CATEGORIES: 0,
    MAX_CATEGORIES: 999999,
  },
  CS11: {
    showCaseTypes: categoryShowCaseType,
    isSpecificCategoryOnly: false,
    MIN_CATEGORIES: 0,
    MAX_CATEGORIES: 999999,
  },
  CS12: {
    showCaseTypes: categoryShowCaseType,
    isSpecificCategoryOnly: false,
    MIN_CATEGORIES: 0,
    MAX_CATEGORIES: 999999,
  },
  CS13: {
    showCaseTypes: categoryShowCaseType,
    isSpecificCategoryOnly: false,
    MIN_CATEGORIES: 0,
    MAX_CATEGORIES: 999999,
  },
  CS14: {
    showCaseTypes: categoryShowCaseType,
    isSpecificCategoryOnly: false,
    MIN_CATEGORIES: 0,
    MAX_CATEGORIES: 999999,
  },
  CS15: {
    showCaseTypes: categoryShowCaseType,
    isSpecificCategoryOnly: false,
    MIN_CATEGORIES: 0,
    MAX_CATEGORIES: 999999,
  },
  CS16: {
    showCaseTypes: categoryShowCaseType,
    isSpecificCategoryOnly: false,
    MIN_CATEGORIES: 0,
    MAX_CATEGORIES: 999999,
  },
  CS17: {
    showCaseTypes: categoryShowCaseType,
    isSpecificCategoryOnly: false,
    MIN_CATEGORIES: 0,
    MAX_CATEGORIES: 999999,
  },
  CS18: {
    showCaseTypes: categoryShowCaseType,
    isSpecificCategoryOnly: false,
    MIN_CATEGORIES: 0,
    MAX_CATEGORIES: 999999,
  },
  CS19: {
    showCaseTypes: [],
    isSpecificCategoryOnly: true,
    MIN_CATEGORIES: 0,
    MAX_CATEGORIES: 3,
  },
  CS20: {
    showCaseTypes: categoryShowCaseType,
    isSpecificCategoryOnly: false,
    MIN_CATEGORIES: 0,
    MAX_CATEGORIES: 999999,
  },
  CS21: {
    showCaseTypes: categoryShowCaseType,
    isSpecificCategoryOnly: false,
    MIN_CATEGORIES: 0,
    MAX_CATEGORIES: 999999,
  },
  CS22: {
    showCaseTypes: categoryShowCaseType,
    isSpecificCategoryOnly: false,
    MIN_CATEGORIES: 0,
    MAX_CATEGORIES: 999999,
  },
  CS23: {
    showCaseTypes: categoryShowCaseType,
    isSpecificCategoryOnly: false,
    MIN_CATEGORIES: 0,
    MAX_CATEGORIES: 999999,
  },
  CS24: {
    showCaseTypes: categoryShowCaseType,
    isSpecificCategoryOnly: false,
    MIN_CATEGORIES: 0,
    MAX_CATEGORIES: 999999,
  },
  CS25: {
    showCaseTypes: categoryShowCaseType,
    isSpecificCategoryOnly: false,
    MIN_CATEGORIES: 0,
    MAX_CATEGORIES: 999999,
  },
};

const productShowCaseType = [
  {
    typeName: 'All Products',
    value: '4',
  },
  {
    typeName: 'Latest Products',
    value: '1',
  },
  {
    typeName: 'Featured Products',
    value: '2',
  },
  {
    typeName: 'Specific Products',
    value: '3',
  },
];

export const PRODUCT_SHOWCASE_LAYOUT_CONFIG = {
  PS1: {
    showCaseTypes: productShowCaseType,
    isSpecificProductsOnly: false,
    MIN_PRODUCTS: 0,
    MAX_PRODUCTS: 999999,
    RECOMMENDED_PRODUCT_COUNT: 12,
  },
  PS2: {
    showCaseTypes: productShowCaseType,
    isSpecificProductsOnly: false,
    MIN_PRODUCTS: 0,
    MAX_PRODUCTS: 999999,
    RECOMMENDED_PRODUCT_COUNT: 12,
  },
  PS3: {
    showCaseTypes: productShowCaseType,
    isSpecificProductsOnly: false,
    MIN_PRODUCTS: 0,
    MAX_PRODUCTS: 999999,
    RECOMMENDED_PRODUCT_COUNT: 12,
  },
  PS4: {
    showCaseTypes: productShowCaseType,
    isSpecificProductsOnly: false,
    MIN_PRODUCTS: 0,
    MAX_PRODUCTS: 999999,
    RECOMMENDED_PRODUCT_COUNT: 12,
  },
  PS5: {
    showCaseTypes: productShowCaseType,
    isSpecificProductsOnly: false,
    MIN_PRODUCTS: 0,
    MAX_PRODUCTS: 999999,
    RECOMMENDED_PRODUCT_COUNT: 12,
  },
  PS6: {
    showCaseTypes: productShowCaseType,
    isSpecificProductsOnly: false,
    MIN_PRODUCTS: 0,
    MAX_PRODUCTS: 999999,
    RECOMMENDED_PRODUCT_COUNT: 12,
  },
  PS7: {
    showCaseTypes: productShowCaseType,
    isSpecificProductsOnly: false,
    MIN_PRODUCTS: 0,
    MAX_PRODUCTS: 999999,
    RECOMMENDED_PRODUCT_COUNT: 12,
  },
  PS8: {
    showCaseTypes: productShowCaseType,
    isSpecificProductsOnly: false,
    MIN_PRODUCTS: 0,
    MAX_PRODUCTS: 999999,
    RECOMMENDED_PRODUCT_COUNT: 12,
  },
  PS9: {
    showCaseTypes: productShowCaseType,
    isSpecificProductsOnly: false,
    MIN_PRODUCTS: 0,
    MAX_PRODUCTS: 999999,
    RECOMMENDED_PRODUCT_COUNT: 12,
  },
  PS10: {
    showCaseTypes: productShowCaseType,
    isSpecificProductsOnly: false,
    MIN_PRODUCTS: 0,
    MAX_PRODUCTS: 999999,
    RECOMMENDED_PRODUCT_COUNT: 12,
  },
  PS11: {
    showCaseTypes: productShowCaseType,
    isSpecificProductsOnly: false,
    MIN_PRODUCTS: 0,
    MAX_PRODUCTS: 999999,
    RECOMMENDED_PRODUCT_COUNT: 12,
  },
  PS12: {
    showCaseTypes: productShowCaseType,
    isSpecificProductsOnly: false,
    MIN_PRODUCTS: 0,
    MAX_PRODUCTS: 999999,
    RECOMMENDED_PRODUCT_COUNT: 12,
  },
  PS13: {
    showCaseTypes: [],
    isSpecificProductsOnly: true,
    MIN_PRODUCTS: 0,
    MAX_PRODUCTS: 1,
    RECOMMENDED_PRODUCT_COUNT: 1,
  },
  PS14: {
    showCaseTypes: productShowCaseType,
    isSpecificProductsOnly: false,
    MIN_PRODUCTS: 0,
    MAX_PRODUCTS: 999999,
    RECOMMENDED_PRODUCT_COUNT: 12,
  },
  PS15: {
    showCaseTypes: productShowCaseType,
    isSpecificProductsOnly: false,
    MIN_PRODUCTS: 0,
    MAX_PRODUCTS: 999999,
    RECOMMENDED_PRODUCT_COUNT: 12,
  },
  PS16: {
    showCaseTypes: productShowCaseType,
    isSpecificProductsOnly: false,
    MIN_PRODUCTS: 0,
    MAX_PRODUCTS: 999999,
    RECOMMENDED_PRODUCT_COUNT: 9,
  },
};

export const getMaximumProductCategory = (
  list: Array<{ id: number; title: string; price: string; images: Array<string> }>,
  maxToBeAdded: number,
): Array<{ id: number; title: string; price: string; images: Array<string> }> => {
  return list?.slice(0, maxToBeAdded);
};

export const settingOption = [
  'Profile',
  'Logo & Favicon',
  'Social Media Links',
  'Custom CSS',
  'Page Scripts',
  'Store Policies',
  'GST Information',
  'Communication',
];
export const HIDDEN_LAYOUT = [];
