import { getLambdaLink } from '@common/utils';

import DEFAULT_PRODUCT_IMAGE from '../images/DefaultProductImage.png';
import { computeProductHash, shouldUseCustomNavigation } from '../utils';
import { getStoreUrl, virtualEventVenue } from '../utils/constants';
import { isExpired } from '../utils/date';
import * as CustomField from './CustomField';
import * as Group from './Group';
import * as Option from './Option';
import * as Variant from './Variant';

export interface Product {
  hash: string;
  id: number;
  sku: string;
  title: string;
  categories: {
    name: string;
    id: number;
  }[];
  price: number;
  seoSettings: {
    metaTitle: string;
    metaCanonicalUrl: string;
    metaKeywords: string[];
    metaDescription: string;
  };
  finalPrice: number;
  hasDiscount: boolean;
  hasOptions: boolean;
  hasCustomField: boolean;
  discountedPrice?: number;
  stock: number;
  inStock: boolean;
  type: string;
  groupId: number;
  images: Array<string>;
  shortDescription: string;
  isFeatured: boolean;
  slug: string;
  event: {
    startDate: string;
    endDate: string;
    venue: string;
  };
  isFree: boolean;
  maxQuantity: number;
  minQuantity: number;
  unlimitedQuantity: boolean;
  optionNames: Array<string>;
  optionValues: Array<string>;
  allOptionValues: Array<Array<string>>;
  storeLink: string;
  referredStoreLinks: Record<PRODUCT_REFERRED_FROM, string>;
  sortOrder: number;
  fullURL: string;
  payWhatYouWant: boolean;
  shipInDays: number;
  shippingCharges: number;
  averageRating: number;
  ratingScore: number;
  ratingVotes: number;
  shortLink: string;
  digitalFile: string | null;
  pageTitle: string;
  description: string;
  shipping: boolean;
  attributes: {
    group: Group.default[];
  };
  options?: Option.default[];
  customFields?: CustomField.default[];
  variants?: Variant.default[];
  created: string;
  modified: string;
  savings: SavingsType;
  hasVariants: boolean;
  isBaseProduct: boolean;
  isVariant: boolean;
  isEvent: boolean;
  isExpired: boolean;
  bundleDiscountAmount?: number;
  bundleId?: number;
  bundleDetails?: {
    primaryProduct?: boolean;
    discountedPrice?: number;
    discountAmount?: number;
    discountPercentage?: number;
  };
  productVideoEnabled?: boolean;
  productVideoUrl?: string;
}

interface ProcessResponseOptions {
  productCardWidth?: number;
  productCardHeight?: number;
}

export const NUM_VARIANTS = 3;

export const processResponse = (
  product: Record<string, never>,
  processResponseOptions?: ProcessResponseOptions,
): Product => {
  const { productCardWidth, productCardHeight } = processResponseOptions || {};
  let optionNames = [];
  for (let i = 1; i <= NUM_VARIANTS; i++) {
    optionNames.push(product[`opt${i}_name`]);
  }
  optionNames = optionNames.filter((value) => value);

  let optionValues = [];
  for (let i = 1; i <= NUM_VARIANTS; i++) {
    optionValues.push(product[`opt${i}_value`]);
  }
  optionValues = optionValues.filter((value) => value);

  const group = (product.attributes as Record<string, Record<string, never>[]>)?.groups?.map((group) => {
    return Group.processResponse(group);
  });
  const options = (product?.options as Record<string, never>[])?.map((option) => Option.processResponse(option));
  const customFields = (product.custom_fields as Record<string, never>[])?.map((customField) =>
    CustomField.processResponse(customField),
  );
  const variants = ([product, ...(product.variants || [])] as Record<string, never>[])?.map((variant) =>
    Variant.processResponse(variant, optionNames),
  );

  const setOptionValues: Array<Set<string>> = [];
  optionValues.forEach(() => {
    setOptionValues.push(new Set<string>());
  });
  variants?.forEach((variant) => {
    for (let i = 0; i < optionValues.length; i++) {
      setOptionValues[i].add(variant.optionValues[i]);
    }
  });

  const allOptionValues = setOptionValues.map((optionValues) => Array.from(optionValues));
  const storeLink = shouldUseCustomNavigation() ? `/${product.slug}/p${product.group_id}` : `/product/${product.slug}`;
  const referredStoreLinks = Object.keys(PRODUCT_REFERRED_FROM).reduce((acc, curr) => {
    return {
      ...acc,
      [PRODUCT_REFERRED_FROM[curr]]: `${storeLink}?referred_from=${PRODUCT_REFERRED_FROM[curr]}`,
    };
  }, {}) as Record<PRODUCT_REFERRED_FROM, string>;

  const price = product.price != null ? +product.price : null;
  const payWhatYouWant = product.pwyw;
  let discountedPrice = product.discounted_price != null ? +product.discounted_price : null;
  discountedPrice = payWhatYouWant || (discountedPrice != null && discountedPrice >= price) ? null : discountedPrice;

  const bundleDiscountAmount =
    (product.bundle_details as Record<string, never>)?.discount_amount != null
      ? (product.bundle_details as Record<string, never>)?.discounted_price
      : null;

  const hasDiscount = discountedPrice != null || bundleDiscountAmount != null;

  const finalPrice = bundleDiscountAmount ? bundleDiscountAmount : discountedPrice ?? price;
  const savings = calculateSavings(price, discountedPrice);
  const hasVariants = !!optionNames?.length;

  let images = product.images as string[];
  const length = images?.length;
  if (!length) {
    images = [DEFAULT_PRODUCT_IMAGE];
  }
  if (product.image) {
    images.unshift(product.image);
  }
  const originalImages = [...images];
  if (length && productCardWidth && productCardHeight) {
    images = images.map((image) => {
      return getLambdaLink({ link: image, width: productCardWidth, height: productCardHeight });
    });
  }
  const isEvent = product.type === PRODUCT_TYPE.EVENT;
  const seoSettings = {
    metaTitle: (product.seo_settings as Record<string, string>)?.meta_title,
    metaCanonicalUrl: (product.seo_settings as Record<string, string>)?.meta_canonical_url,
    metaKeywords: (product.seo_settings as Record<string, string[]>)?.meta_keywords || [],
    metaDescription: (product.seo_settings as Record<string, string>)?.meta_description,
  };
  const processedProduct = {
    isExpired: isExpired(isEvent && (product.event as Record<string, string>)?.end_date),
    id: product.id,
    hash: (product.id as number)?.toString(),
    sku: product.sku,
    title: product.title,
    categories: product.categories || [],
    storeLink,
    referredStoreLinks,
    hasOptions: product.option_exists ?? !!options?.length,
    hasCustomField: product.option_exists ?? !!customFields?.length,
    hasVariants,
    isBaseProduct: true,
    isVariant: hasVariants,
    price,
    finalPrice,
    discountedPrice,
    hasDiscount,
    savings,
    stock: product.stock,
    inStock: product.in_stock ?? product.stock !== 0,
    type: product.type,
    groupId: +product.group_id,
    isEvent,
    images,
    originalImages,
    shortDescription: product.short_description,
    isFeatured: product.is_featured,
    slug: product.slug,
    event: {
      startDate: (product.event as Record<string, string>)?.start_date,
      endDate: (product.event as Record<string, string>)?.end_date,
      venue: (product.event as Record<string, string>)?.venue ?? virtualEventVenue(),
    },
    isFree: product.is_free,
    maxQuantity: +(product.max_qty || Infinity),
    minQuantity: +(product.min_qty || 1),
    unlimitedQuantity: product.unlimited_qty,
    optionNames,
    optionValues,
    sortOrder: product.sort_order,
    payWhatYouWant,
    shipInDays: +product.ship_in_days,
    shippingCharges: +product.shipping_charges,
    ratingScore: product.rating_score,
    averageRating: product.average_rating,
    ratingVotes: product.rating_votes,
    shortLink: product.short_link,
    digitalFile: product.digital_file,
    pageTitle: product.page_title,
    description: product.description,
    shipping: product.shipping,
    fullURL: `${getStoreUrl()}${storeLink.slice(1)}`,
    attributes: {
      group,
    },
    options: options,
    customFields: customFields,
    variants: variants,
    created: product.created,
    modified: product.modified,
    seoSettings,
    allOptionValues,
    bundleDiscountAmount,
    bundleId: product.bundle_id || null,
    bundleDetails: {
      primaryProduct: (product.bundle_details as Record<string, never>)?.primary_product,
      discountedPrice: (product.bundle_details as Record<string, never>)?.discounted_price,
      discountAmount: (product.bundle_details as Record<string, never>)?.discount_amount,
      discountPercentage: (product.bundle_details as Record<string, never>)?.discount_percentage,
    },
    productVideoEnabled: product.product_video_enabled,
    productVideoUrl: product.product_video_url,
  };
  processedProduct.hash = computeProductHash(processedProduct);
  return processedProduct;
};

export default Product;

export interface SavingsType {
  amount: number;
  price: number;
  percentage: number;
}

export const calculateSavings = (price: number, discountedPrice?: number): SavingsType | undefined => {
  let savings = undefined;
  if (discountedPrice) {
    const amount = price - discountedPrice;
    const percentage = ((amount / price) * 100).toFixed(0);
    savings = { amount, percentage, price };
  }
  return savings;
};

export const PRODUCT_TYPE = {
  PHYSICAL: 'physical-product',
  DIGITAL: 'digital-product',
  EVENT: 'event-tickets',
  OTHERS: 'others',
};

export const PRODUCT_OPTIONS_TYPE = {
  TEXT: 'text',
  RADIO: 'radio',
  CHECKBOX: 'checkbox',
  FILE: 'file',
  MULTI_FILE: 'multi-file',
  TIME: 'time',
  DATE: 'date',
  DATETIME: 'datetime',
};

export enum PRODUCT_CUSTOM_FIELD_TYPE {
  TEXT = 1,
  RADIO = 2,
  CHECKBOX = 3,
  DATE = 4,
  DATETIME = 5,
  FILE = 6,
  MULTI_FILE = 7,
}

export interface ProductOverridePropsType {
  formProductOptions?: Option.FormProductOption[];
  formCustomFields?: CustomField.FormCustomField[];
  finalPrice?: number;
}

export enum PRODUCT_REFERRED_FROM {
  STORE_HOME = 'store-home',
  BESTSELLER = 'bestseller',
  CATEGORY = 'category',
}

export const computeProductLink = ({
  product,
  referredFrom,
}: {
  product: Product | Variant.default;
  referredFrom: PRODUCT_REFERRED_FROM;
}): string => product.referredStoreLinks[referredFrom] || product.storeLink;

export const productListLayoutdimensions = {
  PS1: {
    mobile: { width: 280, height: 280 },
    desktop: { width: 280, height: 280 },
  },
  PS2: {
    mobile: { width: 250, height: 250 },
    desktop: { width: 250, height: 250 },
  },
  PS3: {
    mobile: { width: 184, height: 184 },
    desktop: { width: 286, height: 286 },
  },
  PS4: {
    mobile: { width: 280, height: 280 },
    desktop: { width: 280, height: 280 },
  },
  PS5: {
    mobile: { width: 280, height: 280 },
    desktop: { width: 280, height: 280 },
  },
  PS6: {
    mobile: { width: 260, height: 260 },
    desktop: { width: 280, height: 280 },
  },
  PS7: {
    mobile: { width: 220, height: 220 },
    desktop: { width: 260, height: 260 },
  },
  PS8: {
    mobile: { width: 220, height: 220 },
    desktop: { width: 280, height: 280 },
  },
  PS9: {
    mobile: { width: 248, height: 248 },
    desktop: { width: 248, height: 248 },
  },
  PS10: {
    mobile: { width: 170, height: 170 },
    desktop: { width: 308, height: 308 },
  },

  PS11: {
    mobile: { width: 211, height: 316 },
    desktop: { width: 278, height: 417 },
  },
  PS12: {
    mobile: { width: 359, height: 539 },
    desktop: { width: 370, height: 556 },
  },
  PS13: {
    mobile: { width: 560, height: 947 },
    desktop: { width: 600, height: 1015 },
  },
  PS14: {
    mobile: { width: 357, height: 534 },
    desktop: { width: 278, height: 417 },
  },
  PS15: {
    mobile: { width: 352, height: 597 },
    desktop: { width: 547, height: 927 },
  },

  PS16: {
    mobile: { width: 350, height: 350 },
    desktop: { width: 596, height: 596 },
  },
};
